#game #game-side {
	opacity: 1;
    background-image: url(../../assets/img/palace.JPG);
    display: block;
    background-size: cover;
}
#game #game-list-side {
	margin-bottom: 12px;
    text-align: left;
    background-color: #282c34;
}

#game #game-list-side ul{
	padding: 50px 5vw 0px;
}
#game #game-list-side ul li{
	list-style: none;
}
#game #game-list-side ul li img{
	width: 100%;
}

@media (min-width: 701px){
	#game {
	    position: absolute;
	    width: 100%;
	    min-height: 100vh;
	    top: 0;
	    left: 0;
	}

	#game #game-side {
	    width: 50%;
	    height: 100%;
	    float: left;
	    min-height: 100vh;
	    
	}
	#game #game-list-side {
	    width: 50%;
	    height: 100%;
	    overflow-x: hidden;
	    overflow-y: scroll;
	    position: absolute;
	    right: 0;
	    float: left;
	}

}

@media (max-width: 700px){
	#game #game-side {
	    position: relative;
	    height: 70vh;
	    min-height: 500px;
	    width: 100%;
	    overflow: hidden;
	}


	#game #game-list-side {
	    padding: 0% 6%;
	    -webkit-overflow-scrolling: touch;
	}
}