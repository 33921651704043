#about{
	text-align: left;
	background-image: url(../../assets/img/night.jpg);
    display: block;
    background-size: cover;
    width: 100%;
}
#about h2{
	margin-top: 0;
	padding-top: 20px;
}
#about section{
	padding: 50px;
}
.text-content.about{

	/*background-image: url(../../assets/img/palace.JPG);*/
    display: block;
    background-size: cover;
}

@media (min-width: 701px){
.text-content.about .contents-r{
	margin: 0vw 0 6vw 46vw;
    max-width: 450px;
}
.text-content.history{
	
    
}
.text-content.history .contents-r
{
	width: 60%;
    padding: 8vw 10vw 8vw 10vw;
    max-width: 850px;
}
.text-content.member{
	margin: 0vw 0 6vw 46vw;
	max-width: 450px;
}
.text-content.notice{
	width: 60%;
    padding: 8vw 10vw 8vw 10vw;
    max-width: 850px;
}
}