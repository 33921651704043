.btn-ghost{
	color: #fff;
    border: 2px solid;
    padding: 0px 20px;
}
.btn-ghost:hover{
	color:#282c34;
	background: rgba(255, 255, 255, 0.6);
	transition-duration: 0.5s;

}