.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
	transform: rotate(0deg);
  }
  to {
	transform: rotate(360deg);
  }
}

.App-header .menuBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 2;
}
.menuIcon{
  display: block;
  margin: 10px;
  float: left;
  /* padding: 18px; */
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 35px;
  color: #FFF;
  position: relative;
  top: 0;
}
.menuList{
	position: absolute;
	float: left;
	left: 0;
	top: 0px;
	/* list-style: none; */
	padding: 60px 30px 0;
	/* margin: 30px; */
	/* padding: 0; */
	background: #0f304c; /* Old browsers */
background: -moz-linear-gradient(-45deg, #0f304c 0%, #132238 30%, #0c2d4f 63%, #102433 99%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, #0f304c 0%,#132238 30%,#0c2d4f 63%,#102433 99%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, #0f304c 0%,#132238 30%,#0c2d4f 63%,#102433 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f304c', endColorstr='#102433',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	width: 30vw;
  height: 70vh;

}

.menuList .close{
	float: right;
}
.menuList li{
  display: block;
  text-align: left;
  font-family: 'Gill Sans W01 Medium';
  letter-spacing: 0.16em;
  font-size: 1.1875em;

}

.menuList li a{
    color: white;
    padding: 12px 1vw;
    position: relative;
    display: inline-block;
    transition: all 0.3s;
}

#wrap{
  color: white;
  background-color: #282c34;
}
#main{
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.05em;
}

#top{
	min-height: 100vh;
  width: 100%;
	background-image: url(../../assets/img/abi-cover.png);
  display: flex;
  background-size: cover;
  /*position: absolute;*/
    top: 0;
    left: 0;
    width: 100%;
}
#top .inner{
	margin: auto;
  

}
#top h1{
	  margin-bottom: 5.2vw;
    font-size: 5.2vw;
    font-family: 'Berserker-Regular', serif;
    font-size: 8vw;
    letter-spacing: 0.3em;
}

#top a.explore-a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 2px solid;
}
#top a.explore-a:hover{
		color:#282c34;
		background: rgba(255, 255, 255, 0.6);
		transition-duration: 0.5s;
}
#top .explore-a h4 {
    font-size: 0.90625em;
    letter-spacing: 0.13em;
    position: relative;
    text-transform: uppercase;
    padding: 0px 30px;
    margin: 10px;
}

#top-second{
  height: 500px;
  position: relative;
  padding: 5vh;
}