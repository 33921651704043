body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.pushbar.opened{
display: block;
}

html.pushbar_locked{
    overflow: hidden;
    touch-action: none;
}

.btn-ghost{
	color: #fff;
    border: 2px solid;
    padding: 0px 20px;
}
.btn-ghost:hover{
	color:#282c34;
	background: rgba(255, 255, 255, 0.6);
	-webkit-transition-duration: 0.5s;
	        transition-duration: 0.5s;

}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
	-webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
  }
  to {
	-webkit-transform: rotate(360deg);
	        transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
	-webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
  }
  to {
	-webkit-transform: rotate(360deg);
	        transform: rotate(360deg);
  }
}

.App-header .menuBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 2;
}
.menuIcon{
  display: block;
  margin: 10px;
  float: left;
  /* padding: 18px; */
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 35px;
  color: #FFF;
  position: relative;
  top: 0;
}
.menuList{
	position: absolute;
	float: left;
	left: 0;
	top: 0px;
	/* list-style: none; */
	padding: 60px 30px 0;
	/* margin: 30px; */
	/* padding: 0; */
	background: #0f304c; /* Old browsers */ /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, #0f304c 0%,#132238 30%,#0c2d4f 63%,#102433 99%); /* Chrome10-25,Safari5.1-6 */
background: -webkit-linear-gradient(315deg, #0f304c 0%,#132238 30%,#0c2d4f 63%,#102433 99%);
background: linear-gradient(135deg, #0f304c 0%,#132238 30%,#0c2d4f 63%,#102433 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f304c', endColorstr='#102433',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	width: 30vw;
  height: 70vh;

}

.menuList .close{
	float: right;
}
.menuList li{
  display: block;
  text-align: left;
  font-family: 'Gill Sans W01 Medium';
  letter-spacing: 0.16em;
  font-size: 1.1875em;

}

.menuList li a{
    color: white;
    padding: 12px 1vw;
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

#wrap{
  color: white;
  background-color: #282c34;
}
#main{
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: 0.05em;
}

#top{
	min-height: 100vh;
  width: 100%;
	background-image: url(/static/media/abi-cover.b2742228.png);
  display: flex;
  background-size: cover;
  /*position: absolute;*/
    top: 0;
    left: 0;
    width: 100%;
}
#top .inner{
	margin: auto;
  

}
#top h1{
	  margin-bottom: 5.2vw;
    font-size: 5.2vw;
    font-family: 'Berserker-Regular', serif;
    font-size: 8vw;
    letter-spacing: 0.3em;
}

#top a.explore-a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 2px solid;
}
#top a.explore-a:hover{
		color:#282c34;
		background: rgba(255, 255, 255, 0.6);
		-webkit-transition-duration: 0.5s;
		        transition-duration: 0.5s;
}
#top .explore-a h4 {
    font-size: 0.90625em;
    letter-spacing: 0.13em;
    position: relative;
    text-transform: uppercase;
    padding: 0px 30px;
    margin: 10px;
}

#top-second{
  height: 500px;
  position: relative;
  padding: 5vh;
}
#about{
	text-align: left;
	background-image: url(/static/media/night.c2fb56ac.jpg);
    display: block;
    background-size: cover;
    width: 100%;
}
#about h2{
	margin-top: 0;
	padding-top: 20px;
}
#about section{
	padding: 50px;
}
.text-content.about{

	/*background-image: url(../../assets/img/palace.JPG);*/
    display: block;
    background-size: cover;
}

@media (min-width: 701px){
.text-content.about .contents-r{
	margin: 0vw 0 6vw 46vw;
    max-width: 450px;
}
.text-content.history{
	
    
}
.text-content.history .contents-r
{
	width: 60%;
    padding: 8vw 10vw 8vw 10vw;
    max-width: 850px;
}
.text-content.member{
	margin: 0vw 0 6vw 46vw;
	max-width: 450px;
}
.text-content.notice{
	width: 60%;
    padding: 8vw 10vw 8vw 10vw;
    max-width: 850px;
}
}
#game #game-side {
	opacity: 1;
    background-image: url(/static/media/palace.e990f0dc.JPG);
    display: block;
    background-size: cover;
}
#game #game-list-side {
	margin-bottom: 12px;
    text-align: left;
    background-color: #282c34;
}

#game #game-list-side ul{
	padding: 50px 5vw 0px;
}
#game #game-list-side ul li{
	list-style: none;
}
#game #game-list-side ul li img{
	width: 100%;
}

@media (min-width: 701px){
	#game {
	    position: absolute;
	    width: 100%;
	    min-height: 100vh;
	    top: 0;
	    left: 0;
	}

	#game #game-side {
	    width: 50%;
	    height: 100%;
	    float: left;
	    min-height: 100vh;
	    
	}
	#game #game-list-side {
	    width: 50%;
	    height: 100%;
	    overflow-x: hidden;
	    overflow-y: scroll;
	    position: absolute;
	    right: 0;
	    float: left;
	}

}

@media (max-width: 700px){
	#game #game-side {
	    position: relative;
	    height: 70vh;
	    min-height: 500px;
	    width: 100%;
	    overflow: hidden;
	}


	#game #game-list-side {
	    padding: 0% 6%;
	    -webkit-overflow-scrolling: touch;
	}
}
